import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import TextSection from "./textSection"
import InnerSection from "./innerSection"
import AnimationWrapper from "./animationWrapper"

import "./descriptionSection.css"

const DescriptionSection = ({ location, data }) => {
  return (

      <InnerSection className={`${location}-description description-section ${data.align}`}>

      <div className="image-container">
        <AnimationWrapper>
          <GatsbyImage
            className="image-container"
            objectFit={data.object_fit ? `${data.object_fit}` : "cover"}
            image={data.image.childImageSharp.gatsbyImageData}
            alt={data.title}
          />
        </AnimationWrapper>
      </div>

      <div className={`description-container ${data.align}`}>
        <TextSection data={data} />
      </div>

    </InnerSection>

  )
}

export default DescriptionSection